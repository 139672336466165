
import Vue from 'vue'

export default Vue.extend({
  name: 'SimpleTitleHeader',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  methods: {
    goBack() {
      this.$emit('goBack')
    },
  },
})
