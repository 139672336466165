
import Vue from 'vue'
export default Vue.extend({
  name: 'SettingDelete',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: '',
    },
  },
  data: () => ({}),
  methods: {
    close(yn: boolean) {
      if (yn) {
        this.$emit('onConfirm')
      } else {
        this.$emit('onClose')
      }
    },
  },
})
