
import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import { PutAd, PutAdConsent } from '@/api/accountAPI/UsersAPI'
import SettingDelete from '@/components/common/dialogs/SettingDelete.vue'
import Lottie from '@/components/common/lottie/LottieView.vue'
import { AnimationItem } from 'lottie-web'
import { destroyLottieAnim } from '@/plugins/util/lottie'
import SimpleTitleHeader from '@/components/common/layout/SimpleTitleHeader.vue'
import CellookButton from '@/components/common/buttons/CellookButton.vue'
import MyPageSettingCard from '@/components/views/mypage/MyPageSettingCard.vue'
import { getDevicePush } from '@/plugins/util/appInterface'

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    MyPageSettingCard,
    CellookButton,
    SimpleTitleHeader,
    SettingDelete,
    Lottie,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    snackbar: false,
    clicked: false,
    settingDeleteDialog: false,
    anim: null,
    benefitEventSwitch: false,
    snackbarText: '',
    pushYNSwitch: false,
    snackbarTimer: null,
  }),
  computed: {
    ...mapState('DeviceStore', [
      'deviceSettings',
      'deviceInfo',
      'pushYNFromDevice',
      'userSetting',
    ]),
    needShowReceiveInfo(): boolean {
      //웹에서는 모두 보임
      if (!this.deviceInfo) {
        return true
      }

      //앱에서 로그인 하면 보임
      if (this.deviceInfo && this.isLogined) {
        return true
      }

      //앱이지만 기기 알림설정 꺼지면 안보임
      if (this.deviceInfo && this.pushYNFromDevice === 'N') {
        return false
      }

      return true
    },
    needHideMarketingAgree(): boolean {
      //앱이지만 비로그인이면 마케팅 수신동의 항목 숨김
      if (this.deviceInfo && !this.isLogined) {
        return true
      }

      return false
    },
    needShowPushChange(): boolean {
      //device 알림 켜져있을때
      //혜택/이벤트 정보받기에 종속
      return this.needShowReceiveInfo && this.pushYNFromDevice === 'Y'
    },
    updateText(): string {
      return this.deviceSettings.isLatestVersion
        ? '최신 버전입니다.'
        : '최신 버전으로 업데이트 해주세요.'
    },
    pushYN(): boolean {
      if (this.pushYNFromDevice) {
        return this.pushYNFromDevice && this.deviceSettings.adYN === 'Y'
      }

      return this.deviceSettings.adYN === 'Y'
    },
    appVersion(): string {
      const apiVersion = this.deviceSettings.version

      if (apiVersion && apiVersion !== '0.0.0') {
        return apiVersion
      }
      return this.deviceInfo?.appVer
    },
  },
  async created() {
    try {
      await this.fetchGetSettings()

      if (this.isLogined) {
        await this.fetchGetUserSettings()
      }

      if (this.deviceInfo) {
        await getDevicePush()
      }

      this.pushYNSwitch = this.pushYN
    } catch (error) {
      console.log(error)
    }
  },
  watch: {
    async isLogined(value) {
      await this.fetchGetUserSettings()
      if (this.deviceInfo) {
        await getDevicePush()
      }
    },
  },
  beforeDestroy() {
    destroyLottieAnim(this.anim)
    clearTimeout(this.snackbarTimer)
  },
  methods: {
    ...mapActions('DeviceStore', ['fetchGetSettings', 'fetchGetUserSettings']),
    async goDevicePushSetting() {
      this.$tracking('mypage_setting_devicenotifications')
      if (this.$device.isAndroid) {
        await window.CellookBridge.goDevicePushSetting()
        return
      }
      if (this.$device.isIOS) {
        window.webkit.messageHandlers.goDevicePushSetting.postMessage('')
        return
      }
    },
    onChangePushStatus(isOn: boolean | undefined) {
      if (isOn) {
        this.$tracking('mypage_setting_apppushon')
      } else {
        this.$tracking('mypage_setting_apppushoff')
      }

      if (isOn === false) {
        this.settingDeleteDialog = true
        this.pushYNSwitch = false
        return
      }

      this.setAdPushStatus()
    },
    async setAdPushStatus() {
      try {
        //스낵바 타이머 이슈로 직접 타이머세팅
        clearTimeout(this.snackbarTimer)

        const payload = this.deviceSettings.adYN === 'Y' ? 'N' : 'Y'
        await PutAd({ adYN: payload })
        await this.fetchGetSettings()
        this.pushYNSwitch = this.deviceSettings.adYN === 'Y'

        if (this.$device.isAndroid) {
          window.CellookBridge.setAdPushStatus(payload)
        }
        if (this.$device.isIOS) {
          window.webkit.messageHandlers.setAdPushStatus.postMessage(payload)
        }

        this.snackbarText =
          this.deviceSettings.adYN === 'Y'
            ? '광고성 앱 푸시 정보 알림을 받습니다.'
            : '광고성 앱 푸시 정보 알림을 받지 않습니다.'
        this.snackbar = true
        this.snackbarTimer = setTimeout(() => (this.snackbar = false), 3000)
      } catch (error) {
        console.log('setAdPushStatus', error)
      }
    },
    async onConfirm() {
      this.$tracking('mypage_setting_pushoff_popup_keepbtn')
      this.settingDeleteDialog = false
      await this.setAdPushStatus()
    },
    onClose() {
      this.$tracking('mypage_setting_pushoff_popup_mutebtn')
      this.pushYNSwitch = true
      console.log('onClose', this.pushYNSwitch)
      this.settingDeleteDialog = false
    },
    cellookInstallPage() {
      const userAgent = navigator.userAgent.toLowerCase()
      this.$tracking('mypage_setting_appupdate')
      if (userAgent.search('android') > -1) {
        window.location.href =
          'https://play.google.com/store/apps/details?id=kr.co.greenandgrey.cellook'
      }

      if (
        userAgent.search('iphone') > -1 ||
        userAgent.search('ipod') > -1 ||
        userAgent.search('ipad') > -1
      ) {
        window.location.href = 'https://itunes.apple.com/app/id1614348585'
      }
    },
    handleAnim(anim: AnimationItem | null) {
      this.anim = anim
    },
    onChangeReceiveAgree(isOn: boolean | undefined, d: keyof adConsentType) {
      //스낵바 타이머 이슈로 직접 타이머세팅
      clearTimeout(this.snackbarTimer)

      const value = isOn ? 'Y' : 'N'
      let snackBarPrefixText = ''
      const snackBarSuffixText = isOn ? '받습니다.' : '받지 않습니다.'
      console.log('onChangeReceiveAgree', d, isOn)
      switch (d) {
        case 'smsYN':
          snackBarPrefixText = '문자'
          if (isOn) {
            this.$tracking('mypage_setting_messageon')
          } else {
            this.$tracking('mypage_setting_messageoff')
          }
          break
        case 'emailYN':
          snackBarPrefixText = '이메일'
          if (isOn) {
            this.$tracking('mypage_setting_emailon')
          } else {
            this.$tracking('mypage_setting_emailoff')
          }
          break
      }
      PutAdConsent({ [d]: value })

      this.snackbar = true
      this.snackbarText = `광고성 ${snackBarPrefixText} 정보 알림을 ${snackBarSuffixText}`

      this.snackbarTimer = setTimeout(() => (this.snackbar = false), 3000)
    },
    toDetail() {
      this.$tracking('mypage_setting_termsviewall')
      this.$router.push('/mypage/push-notice')
    },
  },
})

interface adConsentType {
  smsYN?: 'Y' | 'N'
  emailYN?: 'Y' | 'N'
}

interface Data {
  snackbar: boolean
  clicked: boolean
  settingDeleteDialog: boolean
  anim: AnimationItem | null
  benefitEventSwitch: boolean
  snackbarText: string
  pushYNSwitch: boolean
  snackbarTimer: any
}

interface Methods {
  fetchGetSettings: () => Promise<void>
  goDevicePushSetting: () => Promise<void>
  onChangePushStatus(isOn: boolean | undefined): void
  setAdPushStatus: () => Promise<void>
  onConfirm: () => void
  onClose: () => void
  cellookInstallPage: () => void
  handleAnim: (anim: AnimationItem | null) => void
  onChangeReceiveAgree(isOn: boolean | undefined, d: keyof adConsentType): void
  fetchGetUserSettings: () => Promise<void>
  toDetail: () => void
}

interface Computed {
  deviceSettings: DeviceSettings
  deviceInfo: DeviceInfo
  appVersion: string
  pushYN: boolean
  pushYNFromDevice: 'Y' | 'N' | null
  updateText: string
  needShowReceiveInfo: boolean
  needShowPushChange: boolean
  needHideMarketingAgree: boolean
}

interface Props {
  show: boolean
}
